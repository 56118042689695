.body {
  padding-left: 5% !important;
  padding-right: 5% !important;
  margin-top: 5vw;

  .top {
    .faqTag {
      width: fit-content;
      padding: 0 0.5vw;
      color: #6941c6;
      border-color: #6941c6 !important;
      border-radius: 0.8vw;
      font-weight: 500;
      // font-size: 14px;
      font-size: 0.8vw;
      margin-bottom: 1vw;
    }

    .header {
      color: #101828;
      font-style: normal;
      font-weight: 500;
      // font-size: 36px;
      font-size: 1.8vw;
      margin-bottom: 1vw;
    }

    .text {
      color: #475467;
      font-style: normal;
      font-weight: 400;
      font-size: 0.95vw;
      width: 70%;
      text-align: center;
    }
  }

  .bottom {
    width: 88%;
    margin-top: 1vw;

    .box {
      margin-top: 0.9vw;
      .heading {
        font-style: normal;
        font-weight: 500;
        // font-size: 18px;
        font-size: 1vw;
        // line-height: 28px;
        line-height: 1.5vw;
        color: #101828;
      }

      .icon {
        color: #98a2b3;
        font-size: 1.5vw;
        margin-bottom: 0.5vw;
      }

      .text {
        font-style: normal;
        font-weight: 400;
        // font-size: 16px;
        font-size: 0.84vw;
        color: #475467;
        // margin-left: 1vw;
      }
    }
  }

  .loadMore {
    color: #344054 !important;
    background-color: white;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
    border-radius: 8px;
    width: 9vw;
    margin-top: 2vw;
    font-style: normal;
    font-weight: 500;
    font-size: 0.8vw;
    line-height: 1.8vw;
    margin-bottom: 2vw;
  }
}

@media only screen and (max-width: 600px) {
  .body {
    margin-top: 8vw;

    .top {
      .faqTag {
        width: fit-content;
        padding: 0 1.5vw;
        border-radius: 3vw;
        font-weight: 500;
        font-size: 3vw;
        margin-bottom: 2vw;
      }

      .header {
        font-weight: 500;
        font-size: 4.5vw;
        margin-bottom: 1vw;
      }

      .text {
        font-weight: 400;
        font-size: 2.2vw;
      }
    }

    .bottom {
      width: 100%;
      margin-top: 1vw;
      margin-bottom: 2vw;

      .box {
        margin-top: 3vw;
        .heading {
          font-weight: 500;
          font-size: 2.5vw;
          color: #101828;
        }

        .icon {
          color: #98a2b3;
          font-size: 3vw;
        }

        .text {
          font-weight: 500;
          font-size: 2.3vw;
          // margin-left: 2vw !important;
        }
      }
    }

    .loadMore {
      border-radius: 1.5vw;
      width: fit-content;
      margin-top: 2vw;
      font-style: normal;
      font-weight: 400;
      font-size: 3vw;
      line-height: 4vw;
    }
  }
}
