.whyVoila {
  margin-top: 7vw;
  padding-left: 5%;
  padding-right: 5%;

  .heading {
    font-style: normal;
    font-weight: 600;
    // font-size: 36px;
    font-size: 3vw;

    margin-bottom: 3vw;
  }

  .box {
    padding-left: 1vw;
    padding-right: 1vw;
    margin-bottom: 3vw;

    .card {
      background-color: #f4f6fa;
      //   border-radius: 16px;
      border-radius: 0.8vw;
      padding: 2vw 3.9vw;

      .img {
        // width: 70px;
        width: 3.6vw;
        // height: 70px;
        height: 3.6vw;

        margin-top: 1vw;
      }

      .title {
        font-style: normal;
        font-weight: 400;
        // font-size: 28px;
        font-size: 1.5vw;

        margin-top: 1vw;
      }

      .text {
        font-style: normal;
        font-weight: 400;
        // font-size: 24px;
        font-size: 1.25vw;

        margin-top: 1vw;
      }
    }
  }

  input {
    font-style: normal;
    font-weight: 400;
    // font-size: 16px;
    font-size: 1vw;
    height: 100%;
    color: #667085;
  }

  .btn1 {
    font-weight: 600;
    font-size: 1vw;
    margin-left: 1vw;
    color: #ffffff;
    padding: 0.7vw 2vw;
    background-color: #7f56d9;
    border: 1px solid #7f56d9;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }

  .motivate {
    font-weight: 500;
    font-size: 2vw;

    margin-top: 1vw;
  }

  .signUp {
    font-weight: 500;
    font-size: 1.5vw;
    margin-top: 1vw;
  }

  .text {
    font-weight: 400;
    color: #475467;
    font-size: 1vw;

    margin-top: 1vw;
    margin-bottom: 2vw;
  }
}

.body {
  margin-top: 3vw;
  padding-left: 5%;
  padding-right: 5%;
  .tag {
    font-style: normal;
    font-weight: 500;
    // font-size: 20px;
    font-size: 1.045vw;
    color: #6941c6;
    width: fit-content;
    padding: 0 0.8vw;

    border: 1.5px solid #7f56d9;
    border-radius: 0.8vw;
    // border-radius: 24px;
    border-radius: 1.1vw;
    // width: 495px;
  }

  .header {
    font-style: normal;
    font-weight: 700;
    // font-size: 60px;
    font-size: 3.12vw;

    color: #101828;

    margin-top: 2vw;
    margin-bottom: 2vw;
    width: 92%;
  }

  .text {
    width: 92%;
    font-style: normal;
    font-weight: 400;
    // font-size: 28px;
    font-size: 1.46vw;

    color: #101828;

    margin-bottom: 2vw;
  }

  input {
    font-style: normal;
    font-weight: 400;
    // font-size: 16px;
    font-size: 1vw;
    height: 100%;
    color: #667085;
  }

  .btn {
    font-weight: 600;
    // font-size: 18px;
    font-size: 1.2vw;
    color: #ffffff;

    background: #7f56d9;
    border: 1px solid #7f56d9;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 3vw;

    padding: 1vw 2vw;
    margin-bottom: 5vw;
  }

  .btn1 {
    font-weight: 600;
    font-size: 1vw;
    margin-left: 1vw;
    color: #ffffff;
    padding: 0.7vw 2vw;
    background-color: #7f56d9;
    border: 1px solid #7f56d9;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }
}

.intro {
  .top {
    .leftCol {
      padding-top: 7vw;
      padding-left: 5vw;

      .header {
        font-style: normal;
        font-weight: 700;
        font-size: 3.2vw;
        color: #111120;
        margin-bottom: 3vw;
      }

      .text {
        font-style: normal;
        font-weight: 400;
        font-size: 1.35vw;
        color: #475467;
        margin-bottom: 4vw;
      }

      label {
        font-style: normal;
        font-weight: 700;
        font-size: 1vw;

        color: #524d59;
      }

      input {
        background: #f7f4fa;

        border: 1px solid #f9f5ff;

        border-radius: 0.4vw;

        font-weight: 400;
        font-size: 1vw;
        height: 100%;
        color: #d4cee3;
      }

      .btn {
        font-weight: 600;
        font-size: 1vw;
        color: #ffffff;
        padding: 0.7vw 2vw;
        background-color: #7f56d9;
        border: 1px solid #7f56d9;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      }
    }
  }

  .tag {
    font-style: normal;
    font-weight: 500;
    font-size: 1.045vw;
    color: #6941c6;
    width: fit-content;
    padding: 0 0.8vw;

    border: 1.5px solid #7f56d9;
    border-radius: 0.8vw;
    border-radius: 1.1vw;
  }

  .mid {
    margin-bottom: 5vw;

    .content {
      left: 0;
      top: 0;
      overflow: hidden;
      transition: all ease-in-out 1s;

      &:hover {
        opacity: 0;
      }

      .overlayBox {
        background-color: white;
        background-image: -webkit-linear-gradient(
          -52deg,
          #7f56d9 60%,
          rgba(0, 0, 0, 0.5) 40%
        );
        // opacity: 0.7;
      }

      .header {
        font-style: normal;
        font-weight: 700;
        font-size: 2vw;
        line-height: 2vw;
        padding-top: 3vw;
        padding-left: 5vw;
        color: #000000;
        z-index: 1;
      }

      .text {
        font-style: normal;
        font-weight: 400;
        font-size: 1.6vw;
        line-height: 1.5vw;
        padding-left: 5vw;
        color: #636f73;
        z-index: 1;
      }
    }
  }

  .bottom {
    background: #111120;
    padding: 0 5vw;
    .header {
      font-style: normal;
      font-weight: 700;
      font-size: 3vw;

      color: #ffffff;
    }

    .btn {
      margin-top: 1.5vw;

      font-weight: 600;
      font-size: 1vw;
      color: #ffffff;
      padding: 0.7vw 2vw;
      background-color: #7f56d9;
      border: 1px solid #7f56d9;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    }
  }
}

@media only screen and (max-width: 600px) {
  .body {
    padding-left: 2.5%;
    padding-right: 2.5%;
    .tag {
      font-weight: 500;
      font-size: 3vw;
      padding: 0 1vw;
      border-radius: 3vw;
    }

    .header {
      font-weight: 700;
      font-size: 5vw;
      margin-bottom: 4vw;
    }

    .text {
      width: 92%;
      font-weight: 400;
      font-size: 3vw;
      margin-bottom: 5vw;
    }

    .btn {
      font-weight: 600;
      font-size: 3vw;
      border-radius: 1vw;

      padding: 1vw 3vw;
      margin-bottom: 8vw;
    }
  }

  .whyVoila {
    padding-left: 2.5%;
    padding-right: 2.5%;

    .heading {
      font-weight: 500;
      font-size: 5vw;

      margin-bottom: 5vw;
    }

    .box {
      padding-left: 1vw;
      padding-right: 1vw;
      margin-bottom: 3vw;

      .card {
        border-radius: 1vw;
        padding: 2vw 3.9vw;

        .img {
          width: 9vw;
          height: 9vw;

          margin-top: 1vw;
        }

        .title {
          font-weight: 500;
          font-size: 4vw;

          margin-top: 1vw;
        }

        .text {
          font-weight: 300;
          font-size: 2.7vw;

          margin-top: 1vw;
        }
      }
    }

    .btn1 {
      font-weight: 600;
      font-size: 3vw;
      color: #ffffff;

      padding: 1vw 4vw;
      border-radius: 1vw;
      margin-top: 2vw;
      margin-bottom: 2vw;
    }
  }
}
