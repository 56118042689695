.body {
  padding-left: 2% !important;
  padding-right: 2% !important;
  margin-top: 4vw;
  .btn {
    font-weight: 600;
    // font-size: 18px;
    font-size: 1.2vw;
    color: #ffffff;

    background: #475467;
    border: 1px solid #475467;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 3vw;

    padding: 1vw 2vw;
  }

  .top {
    margin-bottom: 2vw;
    padding: 0 1vw;

    .inputBox {
      width: 20vw;
      height: 3vw;

      input {
        font-size: 1vw;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .filterBox {
      min-width: 15vw;
    }
  }
}
