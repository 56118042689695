.body {
  padding-left: 5% !important;
  padding-right: 5% !important;
  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 1.9vw;
    line-height: 2.5vw;
    margin-bottom: 1vw;
    margin-left: 1vw;
  }

  .card {
    padding: 0 0.3vw;
    margin-bottom: 2vw;
    max-width: 20% !important;

    .container {
      width: 100%;
      height: 11vw;
    }

    .bottom {
      // height: 20vw;
      .box {
        padding: 1vw;

        .category {
          text-transform: uppercase;
        }

        .header {
          font-size: 0.9vw;
          font-weight: 500;
          color: #000;
          margin-bottom: 1vw;

          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .text {
          font-size: 0.7vw;
          font-weight: 500;
          color: #909090;
          margin-bottom: 1vw;

          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .author {
          text-transform: uppercase;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .body {
    .heading {
      font-style: normal;
      font-weight: 600;
      font-size: 1.9vw;
      line-height: 2.5vw;
      margin-bottom: 1vw;
      margin-left: 1vw;
    }

    .card {
      padding: 0 0.5vw;
      margin-bottom: 2vw;
      // max-width: 20% !important;

      .container {
        width: 100%;
        height: 40vw;
      }

      .bottom {
        .box {
          padding: 2vw;

          .category {
            text-transform: uppercase;
          }

          .header {
            font-size: 3vw;
            font-weight: 500;
            color: #000;
            margin-bottom: 2vw;
          }

          .text {
            font-size: 2.5vw;
            font-weight: 500;
            color: #909090;
            margin-bottom: 2vw;
          }
        }
      }
    }
  }
}
